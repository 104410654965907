import * as React from "react"
import { GridRow } from "./dashboard"
import LoadingFullScreen from "../locust/components/LoadingFullScreen"
import { on } from "../locust/composables/Hide"
import { css } from "glamor"


export default () => {
    
    const [loaded, setLoaded] = React.useState( false )
    
    React.useEffect(()=>{
        setTimeout(()=>{
          setLoaded( true )
        },1000)
    },[])
    
    return (
        <div style={{ minWidth : '100vw', minHeight : '100vh'}}>
            { !loaded &&  <LoadingFullScreen/> }
            { loaded && <GridRow height={"100vh"}>
                <div {...css({ textAlign : 'center', height : 20, width : '100vw', [on(600)] : { marginTop : -90 }})}>
                    404
                </div>
            </GridRow> }
        </div>
    )
}
