import * as React from "react"
import { GridRow } from "../../pages/dashboard"
import { LoadingIndicator } from "./Auth"
import { css } from "glamor"
import { on } from "../composables/Hide"
import Y from "../composables/Y"



// markup
export default () => {



  return (<div style={{ position : 'absolute', width : '100vw', height : '100vh', display : 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <LoadingIndicator/>
  </div>)


}
